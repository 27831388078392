<template>
  <v-container fluid>
    <v-row>
      <v-col cols="11">
        <h3>Delivery List</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" sm="3">
        <v-text-field label="Rows" v-model="limit" type="number"></v-text-field>
      </v-col>
      <v-col cols="5" sm="3">
        <v-select
          label="Status Filter"
          v-model="filter"
          @change="getList"
          :items="[
            '',
            'ongoing',
            'pending',
            'pickup',
            'pickup_complete',
            'dropoff',
            'delivered',
            'canceled',
            'returned'
          ]"
        ></v-select>
      </v-col>
      <v-col cols="1"
        ><v-btn small fab @click="getList" :loading="loading" color="info">
          <v-icon small>mdi-reload</v-icon>
        </v-btn></v-col
      >
    </v-row>

    <v-row v-if="data" no-gutters>
      <v-col cols="12">
        <template v-for="delivery in data">
          <PmCard
            :delivery="delivery"
            :key="delivery.id"
            :backend="backend"
            @getList="getList"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  components: {
    PmCard: () => import('./PmCard.vue')
  },
  data() {
    return {
      list: null,
      limit: 5,
      loading: false,
      filter: '',
      backend: null
    }
  },
  computed: {
    data() {
      return this.list && this.list.data ? this.list.data.data : null
    }
  },
  mounted() {
    console.log(this.$route)
    this.getList()
  },
  methods: {
    async getList() {
      this.backend = `https://biz.${process.env.VUE_APP_DOMAIN}`
      if (this.$route.query.localhost) {
        this.backend = `http://localhost:5001/takein-dev/us-central1/callback`
      }

      this.loading = true
      const targetUrl = `${this.backend}/pm/list?filter=${this.filter}&limit=${this.limit}`
      console.log('Getting deliveries from:', targetUrl)
      this.list = await axios
        .get(targetUrl)

        .catch(e => {
          this.loading = false
          console.error(e)
          this.images = []
          this.localLoading = false
        })
      this.loading = false
    }
  }
}
</script>
