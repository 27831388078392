var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('h3',[_vm._v("Delivery List")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Rows","type":"number"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}})],1),_c('v-col',{attrs:{"cols":"5","sm":"3"}},[_c('v-select',{attrs:{"label":"Status Filter","items":[
          '',
          'ongoing',
          'pending',
          'pickup',
          'pickup_complete',
          'dropoff',
          'delivered',
          'canceled',
          'returned'
        ]},on:{"change":_vm.getList},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"small":"","fab":"","loading":_vm.loading,"color":"info"},on:{"click":_vm.getList}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-reload")])],1)],1)],1),(_vm.data)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.data),function(delivery){return [_c('PmCard',{key:delivery.id,attrs:{"delivery":delivery,"backend":_vm.backend},on:{"getList":_vm.getList}})]})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }